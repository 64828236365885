import React, { Fragment } from "react"
import PageLayout from '../../layouts/page-layout';
import {graphql} from "gatsby";

const Page = ({data}) => {
  return (
    <PageLayout
      data={data} title="Peer Tools"
      description="Workflow tooling for technology companies"
    >
    <p>Project is currently in active development.</p>
    </PageLayout>
  );
};

export default Page;

export const query = graphql`
  query {
    profile: profileYaml {
      ...ProfileFragment
    }
    site {
      siteMetadata {
        title,
        showThemeLogo
      }
    }
  }
`
